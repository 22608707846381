import styles from "./SkillsAbout.module.css";
import cv from "../../../assets/files/GastonIglesias_IT.pdf"
export default function SkillsAbout() {
  return (
    <div data-aos="fade-left" className={styles.skillsContain}>
      <div className={styles.skillsDecription}>
        <h1>Frontend | Backend Developer Skills</h1>
        <div className={styles.separadorDesc}></div>
        <p>
          Mi enfoque abarca tanto el front-end como el back-end del desarrollo
          web. Lo que más me apasiona es la convergencia de estas dos
          disciplinas, donde la creatividad del diseño se une con la lógica y la
          funcionalidad del código.
        </p>

        <div className={styles.download}>
          <a href={cv} download="mi-cv.pdf">
            Descargar CV
          </a>
        </div>
      </div>

      <div className={styles.skills}>
        <h6>HTML</h6>
        <div className={styles.separadorSkills} style={{ width: "100%" }}></div>

        <h6>CSS || Bootstrap || Tailwind || SASS || PrimeNG</h6>
        <div className={styles.separadorSkills} style={{ width: "100%" }}></div>

        <h6>Javascript || React || Next Js || Angular Js || Typescript</h6>
        <div className={styles.separadorSkills} style={{ width: "70%" }}></div>

        <h6>Node Js || Express Js || Nest Js</h6>
        <div className={styles.separadorSkills} style={{ width: "70%" }}></div>

        <h6>SQL || MongoDB || Firebase</h6>
        <div className={styles.separadorSkills} style={{ width: "60%" }}></div>
      </div>
    </div>
  );
}
