import styles from "./HomeHeader.module.css";
import { Link } from "react-router-dom";

export default function HomeHeader() {
  return (
    <>
      <div data-aos="fade-down" className={styles.headerContain}>
        <div className={styles.infoColumn}>
          <h1>Mi nombre es Gaston Iglesias</h1>
          <h4>¡Hola! Me llamo Gaston. Soy Desarrollador Web</h4>
          <div className={styles.buttonContainHeader}>
            <a href="/about">Leer mas</a>
          </div>
        </div>

        <div className={styles.imgColumn}>
          <img src="https://i.ibb.co/zHfnvQj/gaston-Perfil.jpg" alt="Profile" />
        </div>
      </div>
    </>
  );
}
