import { useParams } from "react-router-dom";
import { projectDetail } from "../../../assets/constants/constants";
import { useEffect, useState } from "react";
import styles from "./ProjectDetails.module.css";
import Separador from "../../Separador/Separador";
export default function ProjectDetail() {
  const [project, setproject] = useState({});
  const { ptitle } = useParams();

  useEffect(() => {
    const findOne = projectDetail.find((p) => p.path === ptitle);
    setproject(findOne);
    console.log(project);
  }, [project, ptitle]);

  return (
    <div className={styles.projectDetailContain}>
      <header data-aos="fade-up">
        <img src={project.logo} alt={project.projectTitle} />
        <h1>{project.projectTitle}</h1>
        <h3>Descripcion general del proyecto</h3>
        <p>{project.projectDescription}</p>
      </header>

      <div data-aos="fade-up" className={styles.gallery}>
        {project.gallery?.length > 0 && (
          <>
            <div className={styles.separadorComp}>
              <Separador
                title="Galeria de imagenes"
                description="Imagenes del proyecto"
              />
            </div>
            <div
              id="carouselExampleFade"
              className="carousel slide carousel-fade carrouselGallery"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={project?.gallery[0]}
                    className="d-block w-100"
                    alt="Gallery"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={project?.gallery[1]}
                    className="d-block w-100"
                    alt="Gallery"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={project.gallery[2]}
                    className="d-block w-100"
                    alt="Gallery"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </>
        )}
      </div>

      <section data-aos="fade-up">
        <Separador
          title="Tecnologías utilizadas"
          description="Estas fueron las tecnologías utilizadas para el desarrollo de este
          proyecto"
        />

        <div className={styles.techContain}>
          {project.tech &&
            project.tech.length > 0 &&
            project.tech.map((tech, index) => {
              return <img key={index} src={tech} alt="Tech" />;
            })}
        </div>
      </section>

      {project.video && (
        <div data-aos="fade-up" className={styles.videoContainer}>
          <Separador
            title="¿Como se ve el sitio?"
            description="En este video podes ver brevemente un pequeño repaso de como se visualiza el sitio web"
          />
          <video controls>
            <source src={project.video} type="video/webm" />
            Tu navegador no admite la reproducción de videos.
          </video>
        </div>
      )}

      {project.link && project.github && (
        <>
          <Separador
            title="Enlaces del proyecto"
            description="Estos son los enlaces al proyecto"
          />
          <div data-aos="fade-up" className={styles.iconContain}>
            <a href={project.github || "#"}>
              <i
                className="fa-brands fa-github"
                style={{ color: "#1e1e1f" }}
              ></i>
            </a>
            <a href={project.link || "#"}>
              <i className="fa-solid fa-eye" style={{ color: "#fda121" }}></i>
            </a>
          </div>
        </>
      )}

      {project.company?.length > 0 && (
        <>
          <Separador
            title="Empresas relacionadas"
            description="Estas son las empresas con las que colabore para este proyecto"
          />
          <div data-aos="fade-up" className={styles.companyContain}>
            <img src={project.company[0]} alt="company" />
            <img src={project.company[1]} alt="company" />
          </div>
        </>
      )}
    </div>
  );
}
