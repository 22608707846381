import BannerSeparador from "../../components/BannerSeparador/BannerSeparador";
import CardsHome from "../../components/CardsHome/CardsHome";
import Description from "../../components/Description/Description";
import Experiencia from "../../components/Experiencia/Experiencia";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import PortfolioHome from "../../components/PortfolioHome/PortfolioHome";
import Separador from "../../components/Separador/Separador";
import "./Home.module.css";
export default function Home() {
  return (
    <>
      <HomeHeader data-aos="fade-down" />
      <Separador title="Sobre mi" description="Un poco de información sobre mi perfil"/>
      <Description/>
      <Separador title="Educación" description="Mi proceso educativo a lo largo de los años"/>
      <CardsHome/>
      <BannerSeparador/>
      <Separador title="Experiencia Laboral" description="Información sobre mi perfil laboral."/>
      <Experiencia/>
      <Separador title="My Portfolio" description="Consulta mis proyectos"/>
      <PortfolioHome/>
    </>
  );
}
