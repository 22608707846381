import styles from "./Separador.module.css";
export default function Separador({ title, description }) {
  return (
    <div data-aos="fade-down" className={styles.separadorContain}>
      <h1>{title}</h1>
      <p>{description}</p>
      <div className={styles.separador}></div>
    </div>
  );
}
