import styles from "./Info.module.css";
export default function Info() {
  return (
    <div className={styles.infoContain}>
      <div data-aos="flip-left" className={styles.data}>
        <i className="fa-solid fa-phone" style={{ color: "#1bae70" }}></i>
        <h6>+54 11 3045 8590</h6>
      </div>
      <div data-aos="flip-up" className={styles.data}>
        <i className="fa-solid fa-house" style={{ color: "#1bae70" }}></i>
        <h6>Buenos Aires, Argentina</h6>
      </div>
      <div data-aos="flip-right" className={styles.data}>
        <i className="fa-solid fa-envelope" style={{ color: "#1bae70" }}></i>
        <h6>gastonig2020@gmail.com</h6>
      </div>
    </div>
  );
}
