import rSmart from "../files/Reclama-smart.mp4";
import eEver from "../files/ExersiseEverReact.mp4";
import weather from "../files/TheWeather.mp4";
import iShareHub from "../files/InfoShareHub_v1.mp4";

export const projectsP = [
  {
    image: "https://i.ibb.co/2db08jh/Data-Job2024.jpg",
    titleId: "data-job",
    title: "Data Job (2024)",
  },
  {
    image: "https://i.ibb.co/kyzx5h9/the-Weather.jpg",
    titleId: "the-weather",
    title: "The Weather",
  },
  {
    image: "https://i.ibb.co/ZJ87qyv/info-Share1.jpg",
    titleId: "info-share-hub",
    title: "InfoShareHub",
  },
];

export const projectC = [
  {
    image: "https://i.ibb.co/wyXMTZ1/reclama-1.jpg",
    titleId: "reclama-smart",
    title: "Reclama Smart",
  },
  {
    image: "https://i.ibb.co/0j0mdWC/fc-seguros0.jpg",
    titleId: "asesoria-seguros-project",
    title: "Fernando Carrillo Seguros",
  },
  {
    image: "https://i.ibb.co/DCwF7kJ/grinpol-1.jpg",
    titleId: "consultorio-grinpol",
    title: "Grinpol"
  }
];

export const projectDetail = [
  {
    logo: "https://i.ibb.co/jw3dcsd/logo.jpg",
    projectTitle: "Reclama Smart",
    projectDescription:
      "Junto con el equipo de Foo Talent, tuve la oportunidad de colaborar con el desarrollo de un sitio web para Reclama Smart, un estudio de abogadas comprometido con la asesoría legal. Desde la presentación de sus servicios hasta la facilidad de contacto, el sitio web ofrece una visión clara de la dedicación de Reclama Smart a sus clientes",
    gallery: [
      "https://i.ibb.co/qp8LXr5/reclama-2.jpg",
      "https://i.ibb.co/WzBdzmW/reclama-3.jpg",
      "https://i.ibb.co/B3Qpv6y/reclama-4.jpg",
    ],
    path: "reclama-smart",
    tech: [
      "https://i.ibb.co/bNH5Pxw/HTML.png",
      "https://i.ibb.co/PhXKD8L/CSS.png",
      "https://i.ibb.co/GdsgDCm/NEXT.png",
      "https://i.ibb.co/fXbnKRP/typescriptt.jpg",
    ],
    company: [
      "https://i.ibb.co/jR4mFZH/foo-Talent.jpg",
      "https://i.ibb.co/jw3dcsd/logo.jpg",
    ],
    link: "https://reclama-smart.vercel.app/",
    github: "https://github.com/Gastonig9/reclama-smart",
    video: rSmart,
  },
  {
    logo: "https://i.ibb.co/72P42ZN/fernando-C-logo.jpg",
    projectTitle: "Sitio web para asesoría de seguros (Fernando Carrillo)",
    projectDescription:
      "En este proyecto, trabajamos junto a Coderhouse y Fernando Carrillo para la implementacion de un sitio web en donde los pueden buscar información y asesoramiento de seguros incorporando presencia online para el negocio y automatizando las cotizaciones iniciales y el reporte de siniestros",
    gallery: [
      "https://i.ibb.co/rFVYbK0/fc-seguros1.jpg",
      "https://i.ibb.co/MgzFjyb/fc-seguros2.jpg",
      "https://i.ibb.co/18KbDMB/fc-seguros3.jpg",
    ],
    path: "asesoria-seguros-project",
    tech: [
      "https://i.ibb.co/bNH5Pxw/HTML.png",
      "https://i.ibb.co/S59w5Nx/css-logo.png",
      "https://i.ibb.co/mSCFpfg/bootstrap.jpg",
      "https://i.ibb.co/RSX7zJy/REACT.png",
    ],
    company: [
      "https://i.ibb.co/B2TGR37/coder.jpg",
      "https://i.ibb.co/ZYxw65J/fernando-C-logo2.jpg",
    ],
    link: "https://fernando-carrillo-seguros.vercel.app/",
    github: "https://github.com/Gastonig9/seguros-fernando",
    video: undefined,
  },
  {
    logo: "https://i.ibb.co/tB1nDwC/Data-Job2024-logo.jpg",
    projectTitle: "Data Job",
    projectDescription:
      "Este sitio web nació como una idea simple a la necesidad de buscar y encontrar un empleo. DataJob es un sitio web de búsqueda de empleo. Los usuarios pueden consultar ofertas de trabajo en al ámbito IT y publicar nuevos trabajos. Cuenta con una interfaz sencilla, con funcionalidades como autenticacion de usuarios, sistema de roles y la posibilidad de poder actualizar informacion en el perfil del usuario. Las principales tecnologias utilizadas son React (para el frontend) y NestJS (para el backend)",
    gallery: [
      "https://i.ibb.co/2db08jh/Data-Job2024.jpg",
      "https://i.ibb.co/0FG4vmj/Data-Job2024-1.jpg",
      "https://i.ibb.co/br0z9y6/Data-Job2024-2.jpg",
    ],
    path: "data-job",
    tech: [
      "https://i.ibb.co/RSX7zJy/REACT.png",
      "https://i.ibb.co/S59w5Nx/css-logo.png",
      "https://i.ibb.co/7Qcyb8w/nestjs-logo.png",
      "https://i.ibb.co/zFL1xyJ/mongodb-logo.png",
    ],
    company: [],
    link: "https://datajob-chi.vercel.app/",
    github: "https://github.com/Gastonig9/Data-Job-v2",
    video: null,
  },
  {
    logo: "https://i.ibb.co/qJrHJVR/icono-Clima.jpg",
    projectTitle: "The Weather",
    projectDescription:
      "The Weather es la convencional aplicación / sitio web del clima. Esta pagina toma la ubicación del usuario a través de la API de geolocalizacion, también el usuario puede elegir buscar una ubicación en el el mundo para obtener información sobre el clima.",
    gallery: ["https://i.ibb.co/kyzx5h9/the-Weather.jpg"],
    path: "the-weather",
    tech: [
      "https://i.ibb.co/bNH5Pxw/HTML.png",
      "https://i.ibb.co/S59w5Nx/css-logo.png",
      "https://i.ibb.co/M6rKQFR/JAVASCRIPT.png",
    ],
    company: [],
    link: undefined,
    github: "https://github.com/Gastonig9/The-Weather/tree/master",
    video: weather,
  },
  {
    logo: "https://i.ibb.co/FX4mCHz/info-Share-logo.png",
    projectTitle: "InfoShareHub",
    projectDescription:
      "InfoShareHub es el proyecto final que realice para el final de la cursada de Desarrollo Backend en Coderhouse. La idea principal es crear un sitio a modo de foro y red social en donde aplique funcionalidades como añadir publicaciones a favoritos, un sistema de Chat (realizado con WebSockets), envio de solicitudes de amistad, crear publicaciones, registro y autenticacion de usuarios (con JSON Web Token), recuperacion de contraseñas por email (utilizando NodeMailer), sistema de roles y editar informacion en el perfil de los usuarios.",
    gallery: [
      "https://i.ibb.co/ZJ87qyv/info-Share1.jpg",
      "https://i.ibb.co/1zVNcfj/info-Share2.jpg",
      "https://i.ibb.co/2d6YGfc/info-Share3.jpg",
      "https://i.ibb.co/DVGmR9g/info-Share4.jpg",
    ],
    path: "info-share-hub",
    tech: [
      "https://i.ibb.co/RSX7zJy/REACT.png",
      "https://i.ibb.co/0Vz8VK0/nodeJs.jpg",
      "https://i.ibb.co/KK7P4cb/express.jpg",
      "https://i.ibb.co/gVVYssz/mongoDB.jpg",
    ],
    company: ["https://i.ibb.co/B2TGR37/coder.jpg"],
    link: "https://info-share-hub.vercel.ap/",
    github: "https://github.com/Gastonig9/post-app",
    video: iShareHub,
  },
  {
    logo: "https://i.ibb.co/c8FHkVw/grinpol-logo.jpg",
    projectTitle: "Grinpol",
    projectDescription:
      "Desarrollo de un sitio web para el Consultorio Odontologico Grinpol, dividido en dos secciones: Landing page para exhibir informacion generla del consultorio y Sistema de gestion para administracion de pacientes, turnos y reportes",
    gallery: [
      "https://i.ibb.co/DCwF7kJ/grinpol-1.jpg",
      "https://i.ibb.co/Tt19NND/grinpol-2.jpg",
      "https://i.ibb.co/126BYw9/grinpol-3.jpg",
      "https://i.ibb.co/Chk8g8B/grinpol-4.jpg",
    ],
    path: "consultorio-grinpol",
    tech: [
      "https://i.ibb.co/RSX7zJy/REACT.png",
      "https://i.ibb.co/WFs9KRS/tailwind.png",
      "https://i.ibb.co/JktqLMD/nestjs.png",
      "https://i.ibb.co/NSKmG2c/postgre-SQL.png",
    ],
    company: [],
    link: "https://grinpol.vercel.app/",
    github: "https://github.com/FooTalent/team-1/tree/master",
    video: null,
  },
];
