import { Link } from "react-router-dom";
import "./Navbar.modules.css"
export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{backgroundColor: "#ffffff"}}>
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="https://i.ibb.co/nDf07jS/logo-wordpress-nav.png" alt="Page Icon"  width={240} height={40}/>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="buttonNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse navbarContain" id="navbarNavAltMarkup">
          <div className="navbar-nav navbarLinks">
            <Link className="nav-link active" aria-current="page" to="/">
                <p>Home</p>
            </Link>

            <Link className="nav-link active" aria-current="page" to="/about">
                <p>About</p>
            </Link>

            <Link className="nav-link active" aria-current="page" to="/projects">
                <p>Projects</p>
            </Link>

            <Link className="nav-link active" aria-current="page" to="/contact">
                <p>Contact</p>
            </Link>

            <Link className="nav-link active" aria-current="page" to="https://wa.me/541130458590" id="buttonChat">
              <p>Charlemos</p>
            </Link>
          </div>

          <Link className="nav-link active" aria-current="page" to="https://wa.me/541130458590" id="buttonChatDesk">
              <p>Charlemos</p>
          </Link>
        </div>
      </div>
    </nav>
  );
}
