import Certificaciones from "../../components/components-about/Certficaciones/Certificaciones";
import DescriptionAbout from "../../components/components-about/DescriptionAbout/DescriptionAbout";
import SkillsAbout from "../../components/components-about/SkillsAbout/SkillsAbout";
import Separador from "../../components/Separador/Separador"
import "./About.module.css";
export default function About() {
  return (
    <>
      <DescriptionAbout />
      <SkillsAbout />
      <Separador title="Certificaciones" description="Algunas de las certificaciones que prueban mi compromiso en estos últimos años"/>
      <Certificaciones/>
    </>
  );
}
