import styles from "./BannerSeparador.module.css";
export default function BannerSeparador() {
  return (
    <>
      <div data-aos="fade-down" className={styles.bannerContain}>
        <div className={styles.bannerElements}>
          <i className="fa-solid fa-code" style={{ color: "#ffffff" }}></i>
          <h1>Explorando Nuestro Proceso de Trabajo</h1>
          <p>Creatividad y compromiso</p>
        </div>
      </div>
    </>
  );
}
