import "./Projects.module.css";
import Separador from "../../components/Separador/Separador";
import { projectsP, projectC } from "../../assets/constants/constants";
import PersonalProjects from "../../components/components-project/PersonalProjects/PersonalProjects";
export default function Projects() {
  return (
    <>
      <div style={{width: "60%", margin: "auto"}}>
      <Separador
        title="Explora mi Trayectoria"
        description="En esta sección, te invito a sumergirte en mi viaje profesional y creativo. Aquí encontrarás una selección de proyectos que he realizado a lo largo de mi carrera."
      />
      </div>
      <PersonalProjects titlePr="Proyectos personales" descriptionPr="Aquí, he reunido una colección de proyectos que surgieron de mi pasión y curiosidad por la tecnología y el desarrollo web." data={projectsP}/>

      <PersonalProjects titlePr="Proyectos con empresas" descriptionPr="Mi experiencia profesional y colaborativa. A lo largo de mi carrera, he tenido el privilegio de trabajar con diversas organizaciones para desarrollar soluciones tecnológicas efectivas y orientadas a resultados." data={projectC}/>
    </>
  );
}
