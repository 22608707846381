import styles from "./Certificaciones.module.css";
export default function Certificaciones() {
  return (
    <div data-aos="fade-up-right" className={styles.certContain}>
      <div className="image">
        <a href="https://www.coderhouse.com/certificados/6380a94d04a465000eeed0c6?lang=es" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/z8WCv04/1.png" alt="Certificado" />
        </a>
      </div>
      <div className="image">
        <a href="https://www.coderhouse.com/certificados/63fbdad170de01000ee25474?lang=es" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/hXVKvVT/2.png" alt="Certificado" />
        </a>
      </div>
      <div className="image">
        <a href="https://www.coderhouse.com/certificados/646174475b0bd100028a23ee?lang=es" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/D9MJnS0/3.png" alt="Certificado" />
        </a>
      </div>
      <div className="image">
        <a href="https://www.coderhouse.com/certificados/653ccbdc8197533131053d39?lang=es" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/c6W25GB/certificado-backend.png" alt="Certificado" />
        </a>
      </div>
      <div className="image">
        <a href="https://www.coderhouse.com/certificados/653ccbdc819753849d053d44?lang=es" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/vmkyLWk/certificado-fullstack.png" alt="Certificado" />
        </a>
      </div>
      <div className="image">
        <a href="https://www.coderhouse.com/ar/certificados/65c738ea3213f72ac77abb88?lang=es" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/D54d2n0/desarrollo-de-aplicaciones.jpg" alt="Certificado" />
        </a>
      </div>
      <div className="image">
        <a href="https://www.udemy.com/certificate/UC-2467ed49-3135-40b4-8989-94a1b0073bab/" target="_blank" rel="noreferrer">
          <img src="https://i.ibb.co/3v6f88c/4.jpg" alt="Certificado" />
        </a>
      </div>

    </div>
  );
}
