import styles from "./CardsHome.module.css";

export default function CardsHome() {
  return (
    <>
      <div data-aos="fade-down" className={styles.cardsContain}>
        <div className={styles.card}>
          <div className={styles.imgCard}>
            <img src="https://i.ibb.co/rkhgHpd/UBA.jpg" alt="UBA" />
          </div>

          <h4>Licenciatura en Sistemas</h4>
          <p>
            Carrera en Licenciatura en Sistemas en la Universidad de Buenos
            Aires. desde 2022
          </p>
        </div>

        <div className={styles.card}>
          <div className={styles.imgCard}>
            <img
              src="https://i.ibb.co/z8RwyRn/Coderhouse.png"
              alt="coderhouse"
            />
          </div>

          <h4>Desarrollo Full Stack</h4>
          <p>
            Carrera de Desarrollo Web Full Stack en la plataforma de Coderhouse.
            sep 2022 – octubre 2023
          </p>
        </div>

        <div className={styles.card}>
          <div className={styles.imgCard}>
            <img src="https://i.ibb.co/d4xsnBs/udemy.png" alt="Udemy" />
          </div>

          <h4>Udemy</h4>
          <p>Cursos de formacion profesional en la plataforma de Udemy.</p>
        </div>
      </div>
    </>
  );
}
