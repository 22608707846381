import FormContact from "../../components/components-contact/FormContact/FormContact";
import Info from "../../components/components-contact/Info/Info";
import "./Contact.module.css";
export default function Contact() {
  return (
    <>
      <Info />
      <FormContact/>
    </>
  );
}
