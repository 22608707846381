import { useRef, useState } from "react";
import "./FormContact.modules.css";
import emailjs from "@emailjs/browser";
import { emailCredentials } from "../../../email.credentials";

export default function FormContact() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [send, setsend] = useState("pending");
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const mailResult = await emailjs.sendForm(
        emailCredentials.SERVICE_ID,
        emailCredentials.TEMPLATE_ID,
        form.current,
        emailCredentials.EMAIL_ID,
      );

      if (mailResult) {
        setsend("send");
        setname("");
        setemail("");
        setmessage("");
      }
    } catch (error) {
      setsend("fail");
      setname("");
      setemail("");
      setmessage("");
      console.log(error)
    }
  };

  const handleChangeName = (e) => {
    setname(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setemail(e.target.value);
  };

  const handleChangeMessage = (e) => {
    setmessage(e.target.value);
  };
  return (
    <div className="formContain">
      <form ref={form} onSubmit={handleSubmit}>
        <div data-aos="fade-right" className="input-group mb-3 inputContain">
          <span className="input-group-text" id="basic-addon1">
            Tu nombre
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Juan Lopez"
            aria-label="Username"
            aria-describedby="basic-addon1"
            name="name"
            onChange={handleChangeName}
            value={name}
            required
          />
        </div>

        <div data-aos="fade-right" className="input-group flex-nowrap inputContain">
          <span className="input-group-text" id="addon-wrapping">
            E-mail
          </span>
          <input
            type="email"
            className="form-control"
            placeholder="juanlopeza@gmail.com"
            aria-label="Username"
            aria-describedby="addon-wrapping"
            name="email"
            onChange={handleChangeEmail}
            value={email}
            required
          />
        </div>

        <div data-aos="fade-right" className="input-group inputContain">
          <span className="input-group-text">Mensaje</span>
          <textarea
            className="form-control"
            aria-label="With textarea"
            name="message"
            placeholder="Tu mensaje"
            onChange={handleChangeMessage}
            value={message}
            required
          ></textarea>
        </div>

        {send === "pending" ? (
          <button data-aos="fade-right" type="submit">Enviar mensaje</button>
        ) : send === "send" ? (
          <div data-aos="fade-right" className="messageSend">
            El mensaje fue enviado con exito
          </div>
        ) : send === "fail" ? (
          <div data-aos="fade-right" className="messageFail">
            Hubo un error al enviar el mensaje. <a href="/contact">Reintentar</a>
          </div>
        ) : null}
      </form>

      <div data-aos="fade-down" className="contactInfo">
        <h2>Estemos en contacto</h2>
        <div className="separadorDesc"></div>
        <p>
          No dudes en ponerte en contacto conmigo a través de los medios que
          prefieras.
        </p>
        <a href="https://www.linkedin.com/in/gast%C3%B3n-iglesias-847221213/">
          <i className="fa-brands fa-linkedin" style={{ color: "#2a2b2d" }}></i>
        </a>
        <a href="https://github.com/Gastonig9">
          <i className="fa-brands fa-github" style={{ color: "#2a2b2d" }}></i>
        </a>
      </div>
    </div>
  );
}
