import styles from "./PortfolioHome.module.css"
export default function PortfolioHome() {
    return (
        <div data-aos="fade-down" className={styles.imagesContain}>
            <div className={styles.images}>
                <img src="https://i.ibb.co/3mswV4d/reclama-1.jpg" alt="Portfolio1" />
                <img src="https://i.ibb.co/7KzjLBG/exersise-Ever.jpg" alt="Portfolio2" />
                <img src="https://i.ibb.co/T0dKPY9/dataJob.jpg" alt="Portfolio3" />
            </div>
            <div className={styles.buttonContain}>
                <a href="/projects">Ver proyectos</a>
            </div>
        </div>
    )
}