import styles from "./PersonalProjects.module.css";
export default function PersonalProjects({ titlePr, descriptionPr, data }) {
  return (
    <>
      <div data-aos="fade-up" className={styles.projectContain}>
        <div className={styles.titleP}>
          <h2>{titlePr}</h2>
          <p>{descriptionPr}</p>
        </div>

        <div className={styles.cardContain}>
          {data.map((project, index) => {
            return (
              <div className={styles.card} key={index}>
                <img src={project.image} alt={project.title} />
                <a href={`/project/view/${project.titleId}`}>
                  <h4>{project.title}</h4>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
