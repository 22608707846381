import React from 'react';
import styles from './Footer.module.css'; // Asegúrate de importar tus estilos CSS

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLogo}>
          <img src="https://i.ibb.co/mCNPyCq/logo-wordpress-FOOTER.png" alt="footerlogo" />
        </div>
        <div className={styles.footerInfo}>
          <div className={styles.contact}>
            <h3>Contacto</h3>
            <p>
              Email: <a href="mailto:gastonig2020@gmail.comr">gastonig2020@gmail.com</a>
            </p>
            <p>
              Teléfono: <a href="#">+54 11 3045 8590</a>
            </p>
          </div>
          <div className={styles.follow}>
            <h3>Sígueme</h3>
            <div className={styles.socialIcons}>
              <a href="#" className={styles.icon}>
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://github.com/Gastonig9" className={styles.icon}>
                <i className="fa-brands fa-github"></i>
              </a>
              <a href="https://www.linkedin.com/in/gast%C3%B3n-iglesias-847221213/" className={styles.icon}>
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
