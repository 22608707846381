import styles from "./Description.module.css";
export default function Description() {
  return (
    <>
      <div data-aos="fade-down" className={styles.descriptionContainer}>
        <div className={styles.columnDescription}>
          <h1>Desarrollando con pasión mientras exploramos el mundo.</h1>
          <p>
            Tengo un firme compromiso con el aprendizaje continuo, estoy siempre
            en busca de nuevos desafíos y oportunidades para expandir mis
            habilidades.
          </p>
          <div className={styles.separadorDesc}></div>
          <div className={styles.buttonContainHeader}>
            <a href="/about">Leer mas</a>
          </div>
        </div>

        <div className={styles.columnDescImg}>
          <img
            src="https://i.ibb.co/VSZGjpH/section-ilustrator.jpg"
            alt="Ilustrativo"
          />
        </div>
      </div>
    </>
  );
}
