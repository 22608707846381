import styles from "./Experiencia.module.css";
export default function Experiencia() {
  return (
    <>
      <div data-aos="fade-down" className={styles.cardsExperience}>
        <div className={styles.card}>
          <h3>Desarrollador de Software</h3>
          <h6>Foo Talent</h6>

          <p>Julio 2023 – Actualidad</p>
          <p>
            Desarrollador de Software en Foo Talent Group principalmente con las tareas de:
            <br /> - Creacion de sitios web para distintos clientes.
            <br /> - Diseños a medida utilizando HTML, CSS, Tailwind, Bootstrap y herramientas de diseño como Figma.
            <br /> - React para la creacion de Single Pages Applications.
            <br /> - Node y Nestjs para crear aplicaciones del lado del servidor.
            <br /> - Trabajo en equipo con metodologías ágiles (SCRUM) utilizando herramientas como Trello y Github Projects.
            <br /> - Sistema de control de versiones utilizando Git y Github.
          </p>
        </div>

        <div className={styles.card}>
          <h3>Desarrollador Front End</h3>
          <h6>Fernando Carrillo, Asesoria de Seguros</h6>

          <p>Septiembre 2023 - Noviembre 2023</p>
          <p>
            Desarrollo de un sitio web para la información y consulta en ventas
            de seguros en Coderhouse, principalmente con las tareas de:
            <br /> -Desarrollo de componentes del lado del cliente utilizando
            React como principal framework de desarrollo.
            <br /> -Diseño responsive con CSS <br /> -Trabajo en un entorno de
            equipo con desarrolladores y diseñadores UX/UI
            <br /> -Sistema de control de versiones con Git
          </p>
        </div>

        <div className={styles.card}>
          <h3>Desarrollador Frontend</h3>
          <h6>Notimation</h6>

          <p>Diciembre 2023 - Mayo 2024</p>
          <p>
            Desarrollador Frontend en Notimation principalmente con las tareas de:
            <br /> - Angular como tecnologia de desarrollo.
            <br /> - Desarrollo de nuevas funcionalidad y solucion de bugs para
            las diferentes propuestas y servicios de la empresa. <br/>
            - Trabajo en un
            entorno de equipo utilizando metodologias agiles utilizando GitHub
            Projects
            <br /> - Sistema de control de versiones con Git
          </p>
        </div>
      </div>
    </>
  );
}
