import styles from "./DescriptionAbout.module.css";
export default function DescriptionAbout() {
  return (
    <div data-aos="fade-right" className={styles.containerDescription}>
      <div className={styles.description}>
        <div className={styles.image}>
          <img
            src="https://i.ibb.co/Kmk4vRL/developer.jpg"
            alt="About Profile"
          />
        </div>

        <div className={styles.trajectory}>
          <h1>Trayectoria y Ambiciones</h1>
          <div className={styles.separadorDesc}></div>

          <p>
            Soy un desarrollador web con un enfoque en la creación de soluciones
            digitales inteligentes. Mi apasiona mucho la tecnología y el mundo
            de la programación.
          </p>

          <p>
            Considero que estoy en constante aprendizaje, y me gustaría que mi
            futuro este lleno de emocionantes desafíos y oportunidades. Me
            gustaría seguir expandiendo mis conocimientos en diversas ramas de
            la programación, explorando nuevas tecnologías.
          </p>
        </div>

        <div className={styles.skills}>
          <h1>Habilidades Destacadas</h1>
          <div className={styles.separadorDesc}></div>

          <p>
            Mi viaje como desarrollador web me ha permitido desarrollar un
            conjunto diverso de habilidades técnicas y creativas. Desde la
            programación front-end hasta el desarrollo back-end,
          </p>

          <p>Siempre estoy buscando oportunidades para aplicar mis habilidades de manera innovadora.</p>
        </div>
      </div>
    </div>
  );
}
